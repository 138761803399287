@import '_sass-vars.scss';
@import '_fonts.scss';

:root {
  --headline-stack: 'League Gothic', impact, helvetica, sans-serif;
  --copy-stack: 'Poppins', verdana, sans-serif;
  --blockquote-stack: 'Baskerville', Georgia, serif;
  --1fr: calc(100vw / 12);
  --half-fr: calc(100vw / 24);
  --qtr-fr: calc(100vw / 48);
  --rad: 0.5rem;
  --shade: rgba(0, 0, 0, 0.3);
  --tint: rgba(255, 255, 255, 0.6);
}

body {
  font: 2ic / 1.75 var(--copy-stack);

  @media screen and (max-width: $media-sm) {
    font-size: 1ic;
  }
}

p {
  margin-bottom: 0;

  &:has(+ p) {
    margin-bottom: 1em;
  }
}

a {
  color: currentColor;

  &:link,
  &:visited {
    text-decoration: underline;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }
}

svg {
  display: block;
}

.headline {
  font: normal var(--header-size, 1em) / 1 var(--headline-stack);
  margin: var(--header-margin-top, 0) 0 var(--header-margin-bottom, 0);
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  position: relative;

  .nametag {
    display: block;
    color: var(--tint);
    font-size: 0.65em;
    line-height: 1;
  }

  @media screen and (min-width: $media-md) {
    &[data-icon] {
      margin-left: 1.35ic;

      &::before {
        content: attr(data-icon);
        position: absolute;
        margin-left: -1.35ic;
      }
    }
  }

  @media screen and (min-width: $media-hg) {
    &[data-icon] {
      margin-left: 0;
    }
  }
}

h1 {
  --header-size: 2em;
  --header-margin-bottom: var(--half-fr);

  @media screen and (min-width: $media-md) {
    --header-size: 3.5em;
  }
}

h2 {
  --header-size: 2em;
  --header-margin-bottom: var(--half-fr);

  @media screen and (min-width: $media-md) {
    --header-size: 3.5em;
  }
}

h3 {
  --header-size: 1em;
  --header-margin-bottom: 1em;

  @media screen and (min-width: $media-lg) {
    --header-size: 1.5em;
  }
}

h3.headline {
  --header-margin-bottom: var(--qtr-fr);
  --header-margin-top: var(--half-fr);

  font-family: var(--copy-stack);
  font-weight: bold;
  letter-spacing: normal;
  // text-transform: none;
}

.wrapper {
  display: flex;
  min-height: 100vh;
  flex-flow: column nowrap;
  @media screen and (min-width: $media-md) {
  }
}

.header {
  flex: 0 1 auto;
  padding: var(--half-fr) var(--1fr);
  border-bottom: 2px solid currentColor;

  .headline {
    margin: 0;
    line-height: 1;
    white-space: nowrap;

    i {
      color: #c00;
    }
  }
}

.main {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

.article {
  --bg: hsl(303, 29%, 30%);
  background-color: var(--bg);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: var(--1fr) 0;

  // min-height: 50rem;
}

.article-intro {
  --bg: hsl(360, 29%, 30%);
}

.article-examples {
  --bg: hsl(79, 29%, 30%);
}

.article-other-interests {
  --bg: hsl(208, 29%, 30%);
}

.article-gifs {
  --bg: hsl(189, 29%, 30%);

  @media screen and (min-width: $media-md) {
  }
}

.section {
  display: grid;
  grid-column: 2 / span 10;
  // grid-row: span 6 / auto;
  // grid-row-start: 2;
  margin-bottom: var(--half-fr);

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: $media-hg) {
    justify-self: center;
    max-width: 40ic;
  }
}

.callout {
  --border: var(--base-color);

  border: 2px solid var(--border);
  border-radius: var(--rad);
  background: var(--shade);
}

.figure {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
}

.aside {
  --bg: hsl(235, 30%, 30%);
  padding: var(--1fr) 0;
  background: var(--bg);
  border-width: 0.15rem 0;
  border-style: solid;
  border-color: currentColor;
  grid-template-columns: repeat(12, 1fr);
  display: grid;

  blockquote {
    font: max(2.5rem, var(--half-fr)) / 1 var(--blockquote-stack);
    letter-spacing: 0.35rem;
    grid-column: 2 / span 10;
    opacity: 0.85;
    position: relative;

    @media screen and (min-width: $media-md) {
      font-size: clamp(2em, var(--1fr), 5em);
      grid-column: 3 / span 8;
    }
  }

  p {
    margin-bottom: 0;
  }

  cite {
    --font-size: 0.5em;

    font: italic var(--font-size) / 1.2 var(--blockquote-stack);
    letter-spacing: 1px;
    opacity: 0.7;
    display: block;
    max-width: 45ic;
    margin: 1em 0 0;

    &::before {
      content: '– ';
    }

    @media screen and (min-width: $media-lg) {
      --font-size: var(--qtr-fr);

      letter-spacing: 0;
      margin: var(--qtr-fr) 0 0 35%;
      translate: var(--1fr) 0;
      width: 50%;
    }
  }
}

.svg-bank {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  bottom: 0;
}

// Examples

section.examples {
  display: flex;
  flex-flow: column nowrap;
  justify-self: center;
  max-width: $media-lg;

  .headline {
    --header-margin-bottom: var(--half-fr);
    /*
    @media screen and (min-width: $media-lg) {
      --header-margin-bottom: var(--half-fr);
    } */
  }

  /*   & > .example {
    margin: 0 0 var(--half-fr);
  } */
}

.example-set {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 0 var(--1fr);
  flex: 1 1 100%;
  font-size: 1rem;

  @media screen and (min-width: $media-md) {
    font-size: inherit;
  }

  @media screen and (min-width: $media-hg) {
    margin-bottom: var(--half-fr);
  }
}

.example {
  display: flex;
  flex: 1 1 100%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  /*   background: rgba(0, 0, 0, 0.2);
  border: 2px solid currentColor;
  position: relative;
  aspect-ratio: 1/1; */

  /*   & > * {
    position: absolute;
  } */
}

.examples > .example-captions {
  margin: var(--half-fr) 0 0;
}

.set-toggle {
  flex-basis: 33%;
}

.headline {
  &.headline-toggle {
    --header-margin-top: 0;
  }

  &.headline-dance {
    --header-margin-top: 0;

    text-align: center;
  }

  &.headline-ding {
    --header-margin-top: 0;

    text-align: right;
  }

  & + .example-set {
    margin-bottom: var(--half-fr);
  }
}

.footer {
  flex: 0 1 auto;
  padding: var(--1fr);
  border-top: 2px solid currentColor;
  display: flex;
  flex-flow: column nowrap;
  gap: var(--1fr);

  @media screen and (min-width: $media-lg) {
    padding: var(--half-fr) var(--1fr);
  }

  section {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-start;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    color: currentColor;
  }

  @media screen and (min-width: $media-lg) {
    flex-direction: row;
    align-items: center;

    section {
      margin: 0 var(--half-fr) 0 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.meta {
  flex-direction: column;

  .headline {
    font-size: 1rem;
    color: var(--tint);
  }

  address {
    font-style: normal;
  }
  p {
    margin: 0;
  }
}

// Socials
.socials {
  flex-grow: 0;
  flex-basis: 0;
  width: auto;
}

.social-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $media-lg) {
    // justify-content: space-between;
  }

  svg {
    aspect-ratio: 1/1;
    fill: currentColor;
    visibility: visible;
    width: 2rem;

    @media screen and (min-width: $media-sm) {
      width: 4rem;
    }
  }
}

.social-link {
  line-height: 0;
  font-size: 0;

  a {
    transition: rotate 150ms ease-out;
    display: block;
    padding: 0.5rem;

    &:link,
    &:visited {
      rotate: 0deg;
    }
    &:hover,
    &:active {
      rotate: -15deg;
    }
  }
}

.bio {
  p {
    margin-bottom: 0;
  }
}
